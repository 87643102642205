import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import withRouter from './utils/withRouter.js'
import QuickLinksDropDownContent, { AdminLinksDropDownContent, DevMenu } from "./menus/QuickLinksDropDownContent";
import { logout } from './utils/auth'
import { toast } from 'react-toast'
import logo from '../assets/img/jetfed-logo.svg';

import { Home, DownArrow, UpArrow } from '../assets/img/icons';

const Header = (props) => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    useEffect(() => {
        setIsNavOpen(false);
    }, [window.location.pathname]);

    let operationalEnvionment = process.env.REACT_APP_ENV;
    let operationalEnvionmentClass = null;

    switch (operationalEnvionment) {
        case "PROD":
            operationalEnvionmentClass = "text-red";
            break;
        case "DEV":
        case null:
        case undefined:
            operationalEnvionmentClass = "text-green"
            operationalEnvionment = "DEV"
            break
        case "SIT":
        case "UAT":
            operationalEnvionmentClass = "text-amber"
            break;
        default:
            operationalEnvionment = "operationalEnvironment is broken"
            operationalEnvionmentClass = "text-red";
            break;
    }

    const isTestSystem = ["DEV", "SIT"].includes(operationalEnvionment)

    return (<header className="main-header">
            <div className="navbar">

                <div className="flex-1 justify-between mx-4">

                    <div className="flex items-end gap-2">
                        <img className="h-10" src={logo} alt="JetFed" />
                        <p className={`font-bold ${operationalEnvionmentClass}`}>
                            {operationalEnvionment}
                        </p>
                    </div>

                    <div
                        className="HAMBURGER-ICON-FOR-MOBILE space-y-2 lg:hidden"
                        onClick={() => setIsNavOpen((prev) => !prev)}
                    >
                        <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                        <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                        <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                    </div>

                </div>


                <div className="flex-none hidden px-2 mx-2 lg:flex">
                    <div className="flex items-stretch">
                        <Link className="btn btn-link btn-sm" to='/'>
                            <img src={Home} alt="Home" />
                        </Link>

                        <Link className='btn btn-link btn-sm text-navbar hover:no-underline' to={`/booking`}>
                            Manage Bookings
                        </Link>

                        {props.currentUser && props.currentUser.isAuthenticated ? (
                            <button className="btn btn-outline btn-neutral btn-sm rounded-btn" onClick={() => logout(props.setCurrentUser)}>
                                Log Out
                            </button>

                        ) : (
                            <>
                                <Link className="btn btn-outline btn-primary btn-sm rounded-btn" to='/signin'>
                                    Sign In
                                </Link>
                                <Link className="btn btn-outline btn-accent btn-sm rounded-btn ml-2" to='/register'>
                                    Register
                                </Link>
                            </>
                        )}
                    </div>
                </div>


            </div>

            {/* MOBILE */}
            <section className={`flex flex-col lg:hidden justify-center ${isNavOpen ? "showMenuNav" : "hidden"}`}>
                <hr />
                <a href="/flights/search" className="flex justify-center my-3">
                    <div className="btn btn-link btn-sm text-navbar hover:no-underline">
                            Search Flights
                    </div>
                </a>
                <div className="flex justify-center my-3">
                    <Link className="btn btn-link btn-sm text-navbar hover:no-underline" to="/Account">
                        My Account
                    </Link>
                </div>
                <div className="flex justify-center my-3">
                    <Link className="btn btn-link btn-sm text-navbar hover:no-underline" to="/booking">
                        My Bookings
                    </Link>
                </div>
                <div className="flex justify-center my-3">
                    <Link className="btn btn-link btn-sm text-navbar hover:no-underline" to="/passports">
                        My Passports
                    </Link>
                </div>

                {isTestSystem && (
                    <div className="dropdown dropdown-end flex justify-center my-3">
                        <div tabIndex="0" className="btn btn-link btn-sm text-navbar hover:no-underline">
                            Dev Menu
                            <img src={DownArrow} alt="" />
                        </div>
                        <DevMenu setCurrentUser={props.setCurrentUser} />
                    </div>
                )}
                <div className="dropdown dropdown-end flex justify-center my-3">
                    <AdminLinksDropDownContent currentUser={props.currentUser} />
                </div>

                <div className="flex justify-center my-3">
                    {props.currentUser && props.currentUser.isAuthenticated ? (
                        <button className="btn btn-outline btn-neutral btn-sm rounded-btn" onClick={() => logout(props.setCurrentUser)}>
                            Log Out
                        </button>

                    ) : (
                        <>
                            <Link className="btn btn-outline btn-primary btn-sm rounded-btn" to='/signin'>
                                Sign In
                            </Link>
                            <Link className="btn btn-outline btn-accent btn-sm rounded-btn ml-2" to='/register'>
                                Register
                            </Link>
                        </>
                    )}
                </div>
                <hr />
            </section>

            <div className="navbar bg-navbar-dark justify-between min-h-6 hidden lg:flex">
                <div>
                {isTestSystem && (
                    <>
                        <a className="mx-2 pointer" onClick={() => props.navigate(-1)}>
                            <kbd className="kbd" >◀︎</kbd>
                        </a>
                        <a className="mx-2 pointer" onClick={() => props.navigate(1)}>
                            <kbd className="kbd" >▶︎</kbd>
                        </a>
                    </>
                )}
                </div>
                <div className="flex gap-4">
                    {isTestSystem && (
                        <div>
                            <div className="dropdown dropdown-end">
                                <div tabIndex="0" className="btn btn-link btn-sm text-navbar hover:no-underline">
                                    Dev Menu
                                    <img src={DownArrow} alt="" />
                                </div>
                                <DevMenu setCurrentUser={props.setCurrentUser} />
                            </div>
                        </div>
                    )}
                    <a href="/flights/search">
                        <div className="btn btn-link btn-sm text-navbar hover:no-underline">
                                Search Flights
                        </div>
                    </a>
                    <div>
                        <div className="dropdown dropdown-end">
                            <div tabIndex="0" className="btn btn-link btn-sm text-navbar hover:no-underline">
                                Quick Links
                                <img src={DownArrow} alt="" />
                            </div>
                            <QuickLinksDropDownContent currentUser={props.currentUser} />
                        </div>
                    </div>
                    <AdminLinksDropDownContent currentUser={props.currentUser} />
                </div>
            </div>

        </header>
    )
}


export default withRouter(Header);