import withRouter from './utils/withRouter.js'

const ApplicationVersion = (props) => {
    return (<div className="application-version">
        <a href={`https://notes.avantry.io/books/proair/page/release-notes-v${process.env.REACT_APP_VERSION}`} target="_blank" rel="noopener noreferrer">v{process.env.REACT_APP_VERSION}</a>
        </div>
    )
}


export default withRouter(ApplicationVersion);