import axios from 'axios';
import { toast } from 'react-toast';
import { handleAxiosError } from '../components/utils/misc';

export default class User {

    constructor(userId = 0, emailAddress = "", firstName = "", lastName = "", password = "", passwordConfirmation = "", isAuthenticated = false, roles = [], emailConfirmed = false)
    {
        this.userId               = userId;
        this.emailAddress         = emailAddress;
        this.firstName            = firstName;
        this.lastName             = lastName;
        this.password             = password;
        this.passwordConfirmation = passwordConfirmation;
        this.isAuthenticated      = isAuthenticated;
        this.roles                = roles;
        this.emailConfirmed       = emailConfirmed;
        this.setCurrentUser       = () => {};
    }

    async singIn()
    {
        const data = {
            "EmailAddress": this.emailAddress,
            "Password": this.password,
        };

        return axios({
            url: `/login`,
            method: 'POST',
            data: data,
            withCredentials: true
        })
        .then(request => {
            if (request.data)
            {
                sessionStorage.setItem("jwt", request.data.SessionToken);
                sessionStorage.setItem("jwtExpiry", request.data.SessionExpiry);
                return User.mapJsonToUser(request.data);
            }
            else
            {
                toast.error("Username or password is incorrect.");
                return null;
            }
        })
        .catch(e => {
            if (e.response && e?.response.status == 401)
            {
                toast.error("Username or password is incorrect.");
            }
            else
            {
                handleAxiosError(e);
            }
            return null;
        });
    }

    async register()
    {
        const data = {
            "EmailAddress": this.emailAddress,
            "Password": this.password,
        };

        return axios({
            url: `/register`,
            method: 'POST',
            data: data,
            withCredentials: true
        })
        .then(request => {
            if (request.data.Success)
            {
                sessionStorage.setItem("jwt", request.data.Data.SessionToken);
                sessionStorage.setItem("jwtExpiry", request.data.Data.SessionExpiry);
                return User.mapJsonToUser(request.data.Data);
            }
            else
            {
                if (request.data.Errors)
                {
                    request.data.Errors.forEach(error => {
                        toast.error(`API ERROR: ${error}`);
                    });
                }
                else
                {
                    toast.error("Username or password is invalid.");
                }

                return null;
            }
        })
        .catch(e => {
            if (e.response && e?.response.status == 401)
            {
                toast.error("Username or password is invalid.");
            }
            else
            {
                handleAxiosError(e);
            }
            return null;
        });
    }

    async update()
    {
        const token = sessionStorage.getItem("jwt");

        const data = {
            "EmailAddress": this.emailAddress,
            "FirstName": this.firstName,
            "LastName": this.lastName,
        };

        return axios({
            url: `/user/update`,
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: data,
            withCredentials: true
        })
        .then(request => {
            if (request.data.Success)
            {
                return User.mapJsonToUser(request.data.Data);
            }
            else
            {
                if (request.data.Errors)
                {
                    request.data.Errors.forEach(error => {
                        toast.error(`API ERROR: ${error}`);
                    });
                }
                else
                {
                    toast.error("Something has gone wrong updating your account.");
                }

                return null;
            }
        })
        .catch(e => {
            if (e.response && e?.response.status == 401)
            {
                toast.error("Something has gone wrong updating your account.");
            }
            else
            {
                handleAxiosError(e);
            }
            return null;
        });
    }


    async resetPassword(currentPassword)
    {
        const token = sessionStorage.getItem("jwt");

        const data = {
            "CurrentPassword": currentPassword,
            "Password": this.password,
            "PasswordConfirmation": this.passwordConfirmation,
        };

        return axios({
            url: `/user/update-password`,
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: data,
            withCredentials: true
        })
        .then(request => {
            if (request.data.Success)
            {
                return true;
            }
            else
            {
                if (request.data.Errors)
                {
                    request.data.Errors.forEach(error => {
                        toast.error(`API ERROR: ${error}`);
                    });
                }
                else
                {
                    toast.error("Something has gone wrong updating your password.");
                }

                return false;
            }
        })
        .catch(e => {
            if (e.response && e?.response.status == 401)
            {
                toast.error("Something has gone wrong updating your password.");
            }
            else
            {
                handleAxiosError(e);
            }
            return false;
        });
    }

    equals(otherUser)
    {
        return (
            this.userId               === otherUser.userId &&
            this.emailAddress         === otherUser.emailAddress &&
            this.password             === otherUser.password &&
            this.passwordConfirmation === otherUser.passwordConfirmation &&
            this.isAuthenticated      === otherUser.isAuthenticated &&
            this.emailConfirmed       === otherUser.emailConfirmed
        );
    }

    hasAccess(role)
    {
        return this.roles.includes(role)
    }

    isEmpty()
    {
        return this.equals(new User());
    }

    static async getSession()
    {
        const token = sessionStorage.getItem("jwt");
        const jwtExpiry = sessionStorage.getItem("jwtExpiry");

        const isTokenValid = token != '' && token != null;
        const isTokenExpired = jwtExpiry == '' || jwtExpiry == null || new Date(jwtExpiry) < new Date();
        if (!isTokenValid || isTokenExpired)
        {
            sessionStorage.clear();
            if (token != null && jwtExpiry != null && new Date(jwtExpiry) < new Date())
            {
                toast.warn('Your session was cleared')
            }
            return new User();
        }
        else
        {
            const request = await axios({
                url: '/sessions',
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                withCredentials: true
            });
            const user = User.mapJsonToUser(request.data);

            return user;
        }
    }

    logout = () => {
        sessionStorage.clear();
        window.location.reload();
    }

    static async getById(userId)
    {
        const token = sessionStorage.getItem("jwt");

        const request = await axios({
            url: `/GetById/${userId}`,
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return User.mapJsonToUser(request.data);
    }

    static async requestPasswordReset(emailAddress)
    {
        const data = {
            "EmailAddress": emailAddress
        };

        const token = sessionStorage.getItem("jwt");

        return await axios({
            url: "/user/password-reset",
            method: 'POST',
            data: data,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(request => {
            if (request.data.Success)
            {
                return true;
            }
            else
            {
                if (request.data.Errors)
                {
                    request.data.Errors.forEach(error => {
                        toast.error(`API ERROR: ${error}`);
                    });
                }
                else
                {
                    toast.error("Something has gone wrong requesting a password reset for your account.");
                }

                return false;
            }
        })
        .catch(e => {
            if (e.response && e?.response.status == 401)
            {
                toast.error("Something has gone wrong requesting a password reset for your account.");
            }
            else
            {
                handleAxiosError(e);
            }
            return false;
        });
    }

    static mapJsonToUser(userData)
    {
        if (!userData) return null;

        return new User(
            userData.UserId,
            userData.EmailAddress,
            userData.FirstName,
            userData.LastName,
            "", // Password, not to be mapped
            "", // Password, not to be mapped
            userData.IsAuthenticated,
            userData.Roles,
            userData.EmailConfirmed
        );
    }
}