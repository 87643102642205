import axios from 'axios';
import { toast } from "react-toast";
import User from '../../models/User'

// Short duration JWT token (5-10 min)
export function getJwtToken() {
    return sessionStorage.getItem("jwt")
}

export function setJwtToken(token) {
    sessionStorage.setItem("jwt", token)
}

// Longer duration refresh token (30-60 min)
export function getRefreshToken() {
    return sessionStorage.getItem("refreshToken")
}

export function setRefreshToken(token) {
    sessionStorage.setItem("refreshToken", token)
}

export function fauxLogin(setCurrentUser)
{
    const user = new User(0, "tiago+navion@avantry.io", "", "", "Developer1!");

    user.singIn().then(setCurrentUser);
}

export function logout(setCurrentUser) {
    sessionStorage.clear();
    setCurrentUser(new User());
}

export function isLoggedIn() {
    const token = sessionStorage.getItem("jwt");
    const jwtExpiry = sessionStorage.getItem("jwtExpiry");

    const isTokenValid = token != '' && token != null;
    const isTokenExpired = jwtExpiry == '' || jwtExpiry == null || jwtExpiry > new Date();

    if (isTokenValid && !isTokenExpired)
    {
        return axios({
            url: `/Sessions`,
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            withCredentials: true
        })
        .then(({ data }) => {
            // TODO(TS): REMOVE THIS
            console.log(data)
            toast.info("Logged in.");
            return data.IsAuthenticated;
        })
        .catch((response) => {
            // TODO(TS): REMOVE THIS
            toast.info("Not Logged In. Request caught");
            console.error(response);
            return false;
        });
    }
    sessionStorage.clear();

    // TODO(TS): REMOVE THIS
    toast.info("Not Logged In. Token invalid or session expired");
    return false;
};