import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
    const navigate = useNavigate();
    const location = useLocation();
    const params   = useParams();

    const [ redirectUrl, setRedirectUrl ]   = useState(null);
    const [ redirectData, setRedirectData ] = useState(null);

    useEffect(() => {
        if (redirectUrl)
        {
            if (redirectData) {
                navigate(redirectUrl, { state: redirectData });
            }
            else
            {
                navigate(redirectUrl, { state: { } });
            }
        }
    }, [redirectUrl, navigate, redirectData]);


    return (
        <WrappedComponent
            {...props}
            {...{ navigate, redirectData: location.state, params, setRedirectUrl, setRedirectData }}
        />
    );
};

export default withRouter;